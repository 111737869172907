<script setup>
import SchedulerOrders from "@/components/DHTMLXCalendar/SchedulerOrders.vue";
import { computed, onMounted, ref, watch } from "vue";
import api from "@/services/api";
import { useRouteQuery } from "vue-use-route-query";
import { DateTime, Interval } from "luxon";
import * as _ from "lodash";

import SettingAside from "@/components/SettingAside.vue";
import SwitcherApp from "@/components/common/inputs/SwitcherApp.vue";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import { useBreakpoints } from "@/composables/common/useBreakpoint";
import RadioButtonsGroup from "@/components/common/inputs/RadioButtonsGroup.vue";
import flatPickr from "vue-flatpickr-component";
import { useStorage } from "@vueuse/core";
import DescriptionScheduler from "@/components/DHTMLXCalendar/DescriptionScheduler.vue";
import AvailabilityOverview from "@/components/DHTMLXCalendar/AvailabilityOverview.vue";
import SchedulerOrdersBeauty from "@/components/DHTMLXCalendar/SchedulerOrdersBeauty.vue";
import CalendarDay from "@/components/CalendarDay.vue";
import Spinner from "@/views/shared/Spinner.vue";

import { useCompanyStore } from "@/storePinia/company";
import { storeToRefs } from "pinia";

import { v4 as uuidv4 } from "uuid";
import ButtonIcon from "@/components/common/other/ButtonIcon.vue";
import ScheduleSettingModal from "@/components/modals/ScheduleSettingModal.vue";
import SelectApp from "@/components/common/SelectApp.vue";

const store = useCompanyStore();
const { company, branch } = storeToRefs(store);

const { isTablet, isMobile } = useBreakpoints();

const defaultItem = {
  key: "all",
  label: "Всі",
};

const isLoading = ref(true);
const isHotel = ref(company.value?.crmMode === "hotel");
const dataLoaded = ref(false);
const itemsFilter = ref([defaultItem]);
const itemsFilterForModal = computed(() => {
  return itemsFilter.value
    .slice(1)
    .map((item) => ({ code: item.key, name: item.label }));
});
const positionsFilter = ref([defaultItem]);
const staffList = ref([]);
const isOpenSettings = ref(false);
const showCancelledServiceOrders = ref(false);
const events = ref([]);
const eventsNotPrice = ref([]);

const datePickerConfig = ref({
  mode: "single",
  dateFormat: "Y-m-d",
  locale: Ukrainian,
  inline: false,
  position: isTablet.value ? "auto center" : "auto left",
  disableMobile: true,
});

const itemsViewCalendarHotel = ref([
  {
    id: 1,
    text: "",
    value: "timeline",
    icon: "menu",
  },
  {
    id: 2,
    text: "",
    value: "month",
    icon: "calendar_month",
  },
]);
const itemsCountDays = ref([
  {
    id: 1,
    text: "День",
    value: 1,
    icon: "",
  },
  {
    id: 2,
    text: "Тиждень",
    value: 7,
    icon: "",
  },
]);
const itemsStep = ref([
  {
    id: 12,
    text: "15 хв",
    value: 15,
    icon: "",
  },
  {
    id: 22,
    text: "30 хв",
    value: 30,
    icon: "",
  },
  {
    id: 32,
    text: "1 год",
    value: 60,
    icon: "",
  },
]);
const itemsMobileView = ref([
  {
    id: 11,
    text: "",
    value: false,
    icon: "aspect_ratio",
  },
  {
    id: 22,
    text: "",
    value: true,
    icon: "aspect_ratio",
  },
]);
const itemsCountDaysTimeline = ref([
  {
    id: 111,
    text: "місяць",
    value: 0,
    icon: "",
  },
  {
    id: 222,
    text: "45 днів",
    value: 45,
    icon: "",
  },
]);

const countDayVisible = useStorage(
  `${company.value?.tenantId}.schedulerCountDays`,
  1
);
watch(countDayVisible, async () => {
  isLoading.value = true;

  await getItems();
  await getEvents();

  isLoading.value = false;
});

const _preloadData = async () => {
  const result = await api.staff.list({
    isActive: 1,
  });

  if (result) {
    itemsFilter.value = itemsFilter.value.concat(
      result.map((item) => {
        let label = item.name;
        if (!isHotel.value) {
          label += " " + "(" + item.positionId.name + ")";
        }
        return {
          key: item._id,
          label: label,
        };
      })
    );
  }

  showCancelledServiceOrders.value =
    branch.value.showCancelledServiceOrders || false;
};

const selectedItem = useStorage(
  `${company.value?.tenantId}.selectedItem`,
  defaultItem
);

watch(
  selectedItem,
  async () => {
    isLoading.value = true;

    await getItems();
    await getEvents();

    isLoading.value = false;
  },
  { deep: true }
);
const selectedPosition = useStorage(
  `${company.value?.tenantId}.selectedPosition`,
  defaultItem
);

watch(
  selectedPosition,
  async () => {
    isLoading.value = true;

    await getItems();
    await getEvents();

    isLoading.value = false;
  },
  { deep: true }
);

const isOneSelected = computed(() => {
  return (
    selectedItem.value.key !== "all" || selectedPosition.value.key !== "all"
  );
});

const staffListTransformed = computed(() => {
  return staffList.value
    .filter((itemFilter) => {
      if (
        selectedPosition.value.key === "all" &&
        selectedItem.value.key === "all"
      ) {
        return true;
      } else if (viewOfHotelCalendar.value === "timeline" && isHotel.value) {
        return true;
      } else if (selectedPosition.value.key === "all") {
        return itemFilter._id === selectedItem.value.key;
      } else if (selectedItem.value.key === "all") {
        return itemFilter.positionId._id === selectedPosition.value.key;
      } else {
        return (
          itemFilter._id === selectedItem.value.key &&
          itemFilter.positionId._id === selectedPosition.value.key
        );
      }
    })
    .map((item) => {
      return {
        key: item._id,
        label: item.name,
        positionId: item.positionId,
        image: item.photo?.url,
      };
    });
});

const itemsEmpty = computed(() => {
  return !staffList.value?.length;
});

async function getItems() {
  try {
    const params = getParams();

    staffList.value = await api.staff.list({ ...params, staffId: "" });

    staffList.value.forEach((item) => {
      if (
        positionsFilter.value.findIndex(
          (i) => i.label === item.positionId.name
        ) === -1
      ) {
        positionsFilter.value.push({
          key: item.positionId._id,
          label: item.positionId.name,
        });
      }
    });
  } catch (e) {
    console.error(e);
  }
}

const eventsTransformed = computed(() => {
  let result = [];
  if (!events.value) {
    return result;
  }

  result = events.value.map((item) => {
    const clients = item.clients?.map((client) => {
      const firstName = _.get(client, "clientId.firstName", "");
      const lastName = _.get(client, "clientId.lastName", "");
      const phoneNumber = _.get(client, "clientId.phoneNumber", "");

      let title = {};
      if (firstName || lastName) {
        title.nameAndSurname = `${firstName} ${lastName}`;
      } else {
        title.nameAndSurname = "";
      }

      if (phoneNumber) {
        title.phoneNumber = `(${phoneNumber})`;
      } else {
        title.phoneNumber = "";
      }

      if (Object.keys(title).length === 0) {
        title = {
          nameAndSurname: "",
          phoneNumber: "",
        };
      }

      return title;
    });

    const services = _.get(item, "services", []);

    const totalPrice = item.fixedFinalPrice || 0;
    const discountAmount = item.discountAmount || 0;
    const totalPaid = item.paidAmount || 0;
    const totalRefunded = item.refundedAmount || 0;
    const totalPaidCorrected = totalPaid - totalRefunded;
    // const totalToPay = totalPrice - totalPaid - totalRefunded;
    // const totalToPay = totalPrice - totalPaid;
    // const totalToPay = totalPrice - totalPaidCorrected - discountAmount;

    const sumToPay =
      item.servicesCost + item.additionalServicesCost - item.discountAmount;
    const alreadyPaid = item.paidAmount - item.refundedAmount;
    const totalToPay = sumToPay - alreadyPaid;

    item.isPaidPartially = false;
    item.isPaidFully = false;

    if (totalPaidCorrected >= totalPrice) {
      item.isPaidFully = true;
    } else if (totalPaidCorrected > 0) {
      item.isPaidPartially = true;
    }

    let serviceFrom;
    let serviceTo;
    let guests = {};

    if (!isHotel.value) {
      serviceFrom = item.serviceStart;
      serviceTo = item.serviceEnd;
    } else {
      const objectDays1 = {
        days: 1,
      };

      serviceFrom = item.serviceDateFrom;
      serviceTo = DateTime.fromISO(item.serviceDateTo)
        .plus(objectDays1)
        .toISODate();
      if (serviceFrom === serviceTo) {
        serviceTo = DateTime.fromISO(serviceTo).plus(objectDays1).toISODate();
      }
      guests = item.guests;
    }

    let customColor = item.color || "";
    if (isHotel.value && viewOfHotelCalendar.value === "month") {
      const staff = staffList.value.find((i) => i._id === item.staffId._id);
      if (staff) {
        customColor = staff.color;
      }
    }

    const start_date =
      DateTime.fromISO(serviceFrom).toJSDate() || DateTime.now().toJSDate();
    const end_date =
      DateTime.fromISO(serviceTo).toJSDate() || DateTime.now().toJSDate();

    return {
      clients: clients,
      room_id: item.staffId._id,
      unit_id: item.staffId._id,
      start_date,
      guests,
      end_date,
      text: "",
      status: item.status,
      color: customColor,
      id: item._id,
      createdSource: item.createdSource,
      paidAmount: totalPaidCorrected,
      price: item.servicePrice || 0,

      totalPaid,
      totalPaidCorrected,
      totalToPay,

      isPaidPartially: item.isPaidPartially,
      isConfirmed: item.isConfirmed,
      isPaidFully: item.isPaidFully,
      additionalServices: item.additionalServices,
      isAdditionalServices: item.additionalServices?.length > 0,
      staffName: item.staffId?.name,
      services: services,
      comment: _.get(item, "comment", ""),
      externalName: item.externalName,
      isOverbooked: item.isOverbooked,
    };
  });

  if (!isHotel.value) {
    return result;
  }
  return [...result, ...JSON.parse(JSON.stringify(eventsNotPrice.value))];
});

async function getEventsNotPrices() {
  const resultArr = [];
  const objectDays1 = {
    days: 1,
  };

  const intervals = Interval.fromDateTimes(
    DateTime.fromISO(datesForParams.value.from),
    DateTime.fromISO(datesForParams.value.to).plus(objectDays1)
  )
    .splitBy({ day: 1 })
    .map((d) => d.start.toISODate());

  await Promise.all(
    staffList.value
      .filter((item) => {
        if (selectedItem.value.key === "all") {
          return true;
        } else if (
          selectedItem.value.key !== "all" &&
          viewOfHotelCalendar.value === "month"
        ) {
          return item._id === selectedItem.value.key;
        } else {
          console.warn("unexpected condition in staffList");
        }
      })
      .map(async (item) => {
        const result = await api.staff.getWorkingHoursRange(item._id, {
          startDate: datesForParams.value.from,
          endDate: datesForParams.value.to,
        });
        intervals.forEach((itemInterval) => {
          if (
            result.findIndex((itemRes) => itemRes.date === itemInterval) < 0
          ) {
            resultArr.push({
              unit_id: item._id,
              room_id: item._id,
              text: "",
              start_date: itemInterval,
              comment: "🚫💰",
              disabled: true,
              end_date: DateTime.fromISO(itemInterval)
                .plus(objectDays1)
                .toISODate(),
            });
          }
        });
        if (result) {
          result.forEach((itemDate) => {
            let _isPriceSet = !!itemDate.price;
            if (!_isPriceSet && itemDate.isActive) {
              (itemDate.pricingPerGuest || []).forEach((subItem) => {
                if (subItem.price) {
                  _isPriceSet = true;
                }
              });
            }
            if (!_isPriceSet || !itemDate.isActive) {
              resultArr.push({
                room_id: item._id,
                unit_id: item._id,
                text: "",
                disabled: true,
                start_date: itemDate.date,
                end_date: DateTime.fromISO(itemDate.date)
                  .plus(objectDays1)
                  .toISODate(),
                comment: "🚫💰",
              });
            }
          });
        }
      })
  );

  return resultArr;
}

async function getEvents() {
  if (isHotel.value) {
    eventsNotPrice.value = await getEventsNotPrices();
  }
  try {
    const params = getParams();
    events.value = await api.serviceOrders.list(params);
  } catch (e) {
    console.error(e);
  }
}

const date = useRouteQuery("preselectedDate", DateTime.now().toISODate());

function updateDate(val) {
  console.log(val, date.value);
  if (val && val !== date.value) {
    date.value = val;
  }
}

const datesForParams = computed(() => {
  const dates = { from: "", to: "" };
  if (isHotel.value) {
    if (viewOfHotelCalendar.value === "timeline") {
      if (countDaysTimeline.value === 0) {
        dates.from = DateTime.fromISO(date.value).startOf("month").toISODate();
        dates.to = DateTime.fromISO(date.value).endOf("month").toISODate();
      } else {
        const objectDaysCount = {
          days: countDaysTimeline.value,
        };

        dates.from = date.value;
        dates.to = DateTime.fromISO(date.value)
          .plus(objectDaysCount)
          .toISODate();
      }
    } else {
      const objectDays7 = {
        days: 7,
      };

      dates.from = DateTime.fromISO(date.value)
        .startOf("month")
        .minus(objectDays7)
        .toISODate();
      dates.to = DateTime.fromISO(date.value)
        .endOf("month")

        .toISODate();
    }
  } else {
    dates.from = DateTime.fromISO(date.value).startOf("day").toISODate();
    if (countDayVisible.value === 1) {
      dates.to = dates.from;
    } else {
      dates.from = DateTime.fromISO(date.value).startOf("week").toISODate();
      dates.to = DateTime.fromISO(date.value).endOf("week").toISODate();
    }
  }
  return dates;
});

const getParams = () => {
  const result = { isActive: 1, sourcePage: "calendar" };
  if (selectedItem.value.key !== "all") {
    result.staffId = selectedItem.value.key;
  } else {
    delete result.staffId;
  }
  if (!isHotel.value) {
    result.serviceDate = datesForParams.value.from;
  }
  if (!isHotel.value && countDayVisible.value === 7) {
    result.serviceDateFrom = datesForParams.value.from;
    result.serviceDateTo = datesForParams.value.to;
  }
  if (isHotel.value) {
    result.serviceDateFrom = datesForParams.value.from;
    result.serviceDateTo = datesForParams.value.to;
  }

  return result;
};

onMounted(async () => {
  isLoading.value = true;

  await _preloadData();

  await getItems();
  await getEvents();

  isLoading.value = false;

  dataLoaded.value = true;
});

watch(
  date,
  async (newVal, prevValue) => {
    if (newVal !== prevValue) {
      isLoading.value = true;

      await getItems();
      await getEvents();

      isLoading.value = false;
    }
  }
  // { immediate: true }
);

const halfEventsTransform = useStorage(
  `${company.value?.tenantId}.calendarHalfEvent`,
  false
);
const smallerView = useStorage(`${company.value?.tenantId}.smallerView`, false);

const viewOfHotelCalendar = useStorage(
  `${company.value?.tenantId}.typeScheduler`,
  "timeline"
);

const timeStep = useStorage(`${company.value?.tenantId}.timeSelected`, 30);
const heightCell = useStorage(`${company.value?.tenantId}.heightCell`, 40);

const countDaysTimeline = useStorage(
  `${company.value?.tenantId}.countDaysTimeline`,
  0
);
watch(
  [viewOfHotelCalendar, countDaysTimeline],
  async () => {
    isLoading.value = true;
    await getEvents();
    isLoading.value = false;
  }
  // { immediate: true }
);

async function changeCancelledServiceOrders() {
  isLoading.value = true;

  await api.branches.updateOne(branch.value._id, {
    showCancelledServiceOrders: showCancelledServiceOrders.value,
  });
  await getEvents();

  isLoading.value = false;
}

const showIconForInfoAboutPayment = useStorage(
  `${company.value?.tenantId}.showIconForInfoAboutPayment`,
  false
);

const optionsEventCountLine = ref([
  {
    label: "1 рядок",
    code: "1",
  },
  {
    label: "2 рядки",
    code: "2",
  },
  {
    label: "3 рядки",
    code: "3",
  },
]);

const selectedEventCountLine = useStorage(
  `${company.value?.tenantId}.eventCountLine`,
  optionsEventCountLine.value[optionsEventCountLine.value.length - 1]
);

const defaultAdditionalSettings = ref([
  {
    title: "1 рядок",
    id: uuidv4(),
    items: [
      {
        id: uuidv4(),
        code: "info",
        label: "Інформація",
        value: true,
      },
      {
        id: uuidv4(),
        code: "countGuests",
        label: "Кількість гостів",
        value: true,
      },
      {
        id: uuidv4(),
        code: "source",
        label: "Джерело бронювання (іконка про онлайн бронь)",
        value: true,
      },
      {
        id: uuidv4(),
        code: "additionalServices",
        label: "Додаткові послуги",
        value: true,
      },
      {
        id: uuidv4(),
        code: "financeInfo",
        label: "Фінансова інформація",
        value: true,
      },
    ],
  },
  {
    title: "2 рядок",
    id: uuidv4(),
    items: [
      {
        id: uuidv4(),
        code: "nameAndSurname",
        label: "Імʼя та прізвище гостя",
        value: true,
      },
      {
        id: uuidv4(),
        code: "phoneNumber",
        label: "Телефон гостя",
        value: true,
      },
    ],
  },
  {
    title: "3 рядок",
    id: uuidv4(),
    items: [{ id: uuidv4(), code: "comment", label: "Коментар", value: true }],
  },
]);

const additionalSettings = useStorage(
  `${company.value?.tenantId}.additionalSettings`,
  [...defaultAdditionalSettings.value]
);

const additionalSettingsTransformed = computed(() => {
  if (additionalSettings.value?.length > 0) {
    return additionalSettings.value?.reduce((acc, currentValue) => {
      currentValue.items.forEach((item) => {
        acc[item.code] = item;
      });

      return acc;
    }, {});
  }
  return {};
});

const isShowScheduleSettingModal = ref(false);

function openScheduleSettingModal() {
  isShowScheduleSettingModal.value = true;
}

function closeScheduleSettingModal() {
  isShowScheduleSettingModal.value = false;
}
</script>

<template>
  <ScheduleSettingModal
    :date="date"
    :is-show="isShowScheduleSettingModal"
    :options-items="itemsFilterForModal"
    @close="closeScheduleSettingModal"
    @update="getItems"
  />
  <div v-if="false" class="container-fluid">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div
    v-if="!itemsEmpty || !isHotel"
    class="d-flex align-items-center mb-2 justify-content-between mb-xl-3"
  >
    <div
      class="w-100 px-3 container-fluid d-flex justify-content-between gap-3 align-items-center"
    >
      <div v-if="!isHotel" class="empty d-lg-none w-6">
        <ButtonIcon
          v-if="countDayVisible === 1 && isTablet"
          @click="openScheduleSettingModal"
        >
          <template #icon>
            <span class="material-symbols-outlined"> person_add </span>
          </template>
        </ButtonIcon>
      </div>
      <div
        :class="{ 'input-date-mob': !isHotel }"
        class="text-start d-flex align-items-center gap-3"
      >
        <flat-pickr
          v-show="!isHotel"
          v-model="date"
          :class="{ 'btn btn-sm btn-outline-secondary': !isTablet }"
          :config="datePickerConfig"
          class="datetimepicker"
        ></flat-pickr>

        <div
          v-if="isHotel"
          id="accordionRental"
          class="accordion w-auto flex-shrink-0"
        >
          <div class="accordion-item">
            <h6 id="headingOne" class="accordion-header">
              <button
                aria-controls="collapseOne"
                aria-expanded="false"
                class="accordion-button py-2 border-bottom collapsed d-flex align-items-center gap-3 gap-lg-5"
                data-bs-target="#collapseOne"
                data-bs-toggle="collapse"
                type="button"
              >
                <span>Зайнятість</span>
                <i
                  aria-hidden="true"
                  class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3"
                ></i>
                <i
                  aria-hidden="true"
                  class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3"
                ></i>
              </button>
            </h6>
          </div>
        </div>
      </div>
      <div class="text-end d-flex gap-2 align-items-center">
        <ButtonIcon
          v-if="countDayVisible === 1 && !isTablet && !isHotel"
          @click="openScheduleSettingModal"
        >
          <template #icon>
            <span class="material-symbols-outlined"> person_add </span>
          </template>
        </ButtonIcon>
        <ButtonIcon
          class="ms-lg-auto d-flex p-2 cursor-pointer px-0 px-lg-2"
          @click="isOpenSettings = true"
        >
          <template #icon>
            <i :class="{ blue: isOneSelected }" class="material-icons">
              tune
            </i>
          </template>
        </ButtonIcon>
      </div>
    </div>
    <div class="text-end">
      <SettingAside :is-open="isOpenSettings" @close="isOpenSettings = false">
        <template #body>
          <div class="mb-2">
            <SelectApp
              v-if="!isHotel"
              v-model="selectedItem"
              :settings="{
                allowEmpty: false,
                options: itemsFilter,
                placeholder: 'Оберіть майстра',
                trackBy: 'key',
                label: 'label',
              }"
            />
          </div>
          <div class="mb-2">
            <SelectApp
              v-if="!isHotel"
              v-model="selectedPosition"
              :settings="{
                allowEmpty: false,
                options: positionsFilter,
                placeholder: 'Оберіть посаду',
                trackBy: 'key',
                label: 'label',
              }"
            />
          </div>
          <div
            v-if="isHotel"
            class="text-center d-block justify-content-center my-2"
          >
            <RadioButtonsGroup
              v-model="viewOfHotelCalendar"
              :items="itemsViewCalendarHotel"
            ></RadioButtonsGroup>
          </div>
          <div
            v-if="isHotel && viewOfHotelCalendar === 'timeline'"
            class="text-center d-block justify-content-center my-2"
          >
            <RadioButtonsGroup
              v-model="countDaysTimeline"
              :items="itemsCountDaysTimeline"
            ></RadioButtonsGroup>
          </div>
          <div
            v-if="!isHotel"
            class="text-center d-block justify-content-center my-2"
          >
            <RadioButtonsGroup
              v-model="countDayVisible"
              :items="itemsCountDays"
            ></RadioButtonsGroup>
          </div>
          <div
            v-if="!isHotel"
            class="text-center d-block justify-content-center my-2"
          >
            <RadioButtonsGroup
              v-model="timeStep"
              :items="itemsStep"
            ></RadioButtonsGroup>
          </div>
          <div v-if="!isHotel" class="text-start mt-3">
            <label class="form-label" for="customRange3"
              >Висота комірки {{ heightCell }}px</label
            >
            <input
              id="customRange3"
              v-model="heightCell"
              class="form-range"
              max="150"
              min="20"
              step="5"
              type="range"
            />
          </div>
          <RadioButtonsGroup
            v-if="isHotel && isMobile && false"
            v-model="smallerView"
            :items="itemsMobileView"
          ></RadioButtonsGroup>
          <div class="d-flex gap-3 mt-3">
            <div class="text-start">
              <span>Показати іконки замість суми оплати/передплати </span>
            </div>
            <SwitcherApp v-model="showIconForInfoAboutPayment" />
          </div>
          <div v-if="!isHotel" class="d-flex gap-3 mt-3">
            <div class="text-start">
              <span> Показати записи в статусі "Відмінено" </span>
            </div>
            <SwitcherApp
              v-model="showCancelledServiceOrders"
              @on-change="changeCancelledServiceOrders"
            />
          </div>
          <div v-if="isHotel" class="d-flex gap-3 mt-3">
            <div class="text-start">
              <span> Показати бронювання в статусі "Відмінено" </span>
            </div>
            <SwitcherApp
              v-model="showCancelledServiceOrders"
              @on-change="changeCancelledServiceOrders"
            />
          </div>
          <div v-if="isHotel" class="mt-3 d-flex gap-3">
            <div class="text-start">
              <span> Змістити подію бронювання на півдоби праворуч </span>
            </div>
            <SwitcherApp v-model="halfEventsTransform" />
          </div>
          <div v-if="isHotel" class="mt-3">
            <span class="text-start d-block mb-2">Висота події:</span>
            <div class="mb-2">
              <SelectApp
                v-model="selectedEventCountLine"
                :settings="{
                  allowEmpty: false,
                  options: optionsEventCountLine,
                  placeholder: 'Оберіть висоту події',
                  trackBy: 'code',
                  label: 'label',
                }"
              />
            </div>
            <div class="mt-4">
              <h5 class="text-start">Відображати інформацію</h5>

              <div
                v-for="block of additionalSettings"
                :key="block.id"
                class="mb-4"
              >
                <span class="text-start h6 text-dark d-block mb-2">{{
                  block.title
                }}</span>
                <div
                  v-for="item of block.items"
                  :key="item.id"
                  class="d-flex justify-content-between gap-3 mb-3"
                >
                  <div class="text-start">
                    <span>
                      {{ item.label }}
                    </span>
                  </div>
                  <SwitcherApp v-model="item.value" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </SettingAside>
    </div>
  </div>
  <div id="collapseOne" class="collapse">
    <div class="accordion-body text-sm opacity-8">
      <AvailabilityOverview :is-hotel="isHotel" :items-empty="itemsEmpty" />
    </div>
  </div>
  <div class="mb-3 container-fluid">
    <div class="select select_room">
      <SelectApp
        v-if="isHotel && viewOfHotelCalendar === 'month'"
        v-model="selectedItem"
        :settings="{
          allowEmpty: false,
          options: itemsFilter,
          placeholder: `${!isHotel ? 'Оберіть майстра' : 'Оберіть номер'}`,
          trackBy: 'key',
          label: 'label',
        }"
      />
    </div>
  </div>
  <div class="wrap flex-grow-1 overflow-hidden d-flex flex-column mb-3">
    <div
      :class="{ loading: isLoading }"
      class="calendar-scheduler-wrapper d-flex flex-column flex-grow-1"
    >
      <Spinner :loaded="!isLoading"></Spinner>
      <SchedulerOrders
        v-if="isHotel === true"
        :additional-settings="additionalSettingsTransformed"
        :count-days-timeline="countDaysTimeline"
        :date="date"
        :eventCountLine="Number(selectedEventCountLine.code)"
        :events="eventsTransformed"
        :half-events-transform="halfEventsTransform"
        :items="staffListTransformed"
        :show-cancelled-service-orders="showCancelledServiceOrders"
        :showIconForInfoAboutPayment="showIconForInfoAboutPayment"
        :smaller-view="smallerView"
        :view="viewOfHotelCalendar"
        @update-date="updateDate"
      />
      <SchedulerOrdersBeauty
        v-if="isHotel === false"
        :count-days-visible="countDayVisible"
        :date="date"
        :events="eventsTransformed"
        :height-cell="heightCell"
        :items="staffListTransformed"
        :show-cancelled-service-orders="showCancelledServiceOrders"
        :showIconForInfoAboutPayment="showIconForInfoAboutPayment"
        :smaller-view="smallerView"
        :step-time="timeStep"
        :view="'unit'"
        @update-date="updateDate"
      />
    </div>
  </div>
  <DescriptionScheduler
    v-if="viewOfHotelCalendar === 'timeline' || !isHotel"
    :is-hotel="isHotel"
  />
  <div
    v-if="isHotel && viewOfHotelCalendar === 'month'"
    class="row mt-2 calendar-legend mx-1 mt-1 text-sm month-legend-style"
  >
    <div
      v-for="item in staffList"
      :key="item.id"
      :style="{
        'background-color': item.color,
        'border-color': item.color,
      }"
      class="col-lg-2 col-sm-12 text-center"
      style="color: black"
    >
      {{ item.name }}
    </div>
  </div>
  <CalendarDay
    v-if="isTablet && !isHotel"
    :date="date"
    @change-date="updateDate"
  />
</template>

<style lang="scss" scoped>
.calendar-scheduler-wrapper {
  position: relative;
  overflow: hidden;

  &.loading {
    opacity: 0.6;
    pointer-events: none;
  }

  :deep(.main) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
  }

  :deep(.loader-container) {
    position: absolute;
    margin-top: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.select {
  max-width: 300px;
  @media (max-width: 768px) {
    max-width: 240px;
    :deep(.multiselect__input),
    :deep(.multiselect__single) {
      font-size: 12px;
    }
    :deep(.multiselect__option) {
      font-size: 12px;
    }
  }
}

.input-date-mob {
  @media (max-width: 991px) {
    position: relative;
    &::after {
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 100%;
      background: url("~@/assets/img/icons/decor.svg") center / 100% 100%;
    }
    input {
      border: none;
      background: transparent;
      width: 100px;
      outline: none;
    }
  }
}

.card .card-body {
  @media (max-width: 768px) {
    padding: 10px;
  }
}

.material-icons.blue {
  color: var(--bs-primary);
}

.month-legend-style > div {
  border-radius: 8px;
}

.accordion-body {
  width: 100%;
  max-width: 400px;
}

.select_room {
  width: 100%;
  max-width: 200px;
  flex-shrink: 0;
  flex-grow: 1;
  @media (max-width: 767px) {
    max-width: 120px;
  }
}
</style>
